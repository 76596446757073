<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <sticky-header :title="$route.meta.title">
    </sticky-header>
    <div class="flex-fill p-3">
      <b-row class="mb-3">
        <b-col cols="12">
          <b-form-input debounce="500" @update="load" placeholder="Search" type="text" v-model="query" />
        </b-col>
      </b-row>
      <div class="d-flex flex-column mt-5 py-3 justify-content-center" v-if="loading">
        <b-spinner class="align-self-center" variant="primary" />
        <p class="align-self-center mt-1 mb-0">Loading...</p>
      </div>
      <b-row>
        <b-col cols="12">
          <b-list-group class="mb-4" v-if="!loading">
            <b-table sortable responsive outlined :items="formattedData" :fields="[{key: 'property', sortable: true}, 'key_hook', 'code', 'actions']">
              <template #cell(actions)="row">
                <div class="align-self-center d-flex flex-md-row flex-column">
                  <router-link class="mb-1 mb-md-0" :to="'/properties/update/' + row.item.property_id" variant="primary" v-b-popover.top.hover="`View Property`"><fa-icon icon="hotel" /></router-link>
                </div>
              </template>
            </b-table>
          </b-list-group>
          <b-pagination-nav :link-gen="paginationLinkGen" :number-of-pages="lastPage" use-router v-if="lastPage > 1" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import StickyHeader from '../components/StickyHeader.vue'
import { mapActions, mapState } from 'vuex'
import map from "lodash/map";

export default {
  components: { StickyHeader },
  data() {
    return {
      query: '',
      selectedPropertyKey: {},
      formattedData: []
    };
  },
  async mounted() {
    await this.fetch();
  },
  computed: {
    ...mapState('propertyKeys', ['data', 'loading', 'lastPage']),
  },
  methods: {
    ...mapActions('propertyKeys', ['fetch']),
    paginationLinkGen(pageNo) {
      return pageNo === 1
        ? {name: 'property-keys', query: pickBy({...this.$route.params, page: null}, identity)}
        : {name: 'property-keys', query: {...this.$route.params, page: pageNo}};
    },
    pluralise(count, term) {
      if (count === 1) return term;
      return `${term}s`;
    },
    edit(id) {
      this.selectedProperty = this.data.find(c => c.id === id);

      if (!(this.$route.name === 'property-keys.update' && this.$route.params.propertyId === id)) {
        this.$router.push({ name: 'property-keys.update', params: { propertyId: id } });
      }
    },
    async load() {
      return await this.fetch({
        query: this.query,
        page: this.$route.query.page || 1
      });
    },
  },
  watch: {
    async '$route'(newValue, value) {
      if (!value.meta.modalId && newValue.meta.modalId) {
        return this.$bvModal.show(newValue.meta.modalId);
      }

      if (newValue.name === value.name && newValue.query.page !== value.query.page) {
        await this.load();
      }

      return this.$bvModal.hide(value.meta.modalId);
    },
    data(data) {
      this.formattedData = map(data, propertyKey => ({
        property_id: propertyKey.id,
        property: propertyKey.name,
        key_hook: propertyKey.key.key_hook,
        code: propertyKey.key.keybox_code,
      }))
    }
  }
}
</script>

<style>

</style>
